import Vue from 'vue'
import VueRouter from 'vue-router'
import Layouts from '@/layouts'
Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: 'home',
    component: Layouts,
    children: [
      {
        path: '/home',
        meta: { title: 'Home', icon: '' },
        component: () => import('@/views/home'),
      },
      {
        path: '/Statistics',
        meta: { title: 'Statistics', icon: '', Show: true },
        component: () => import('@/views/Statistics'),
      },
      {
        path: '/Referral',
        meta: { title: 'Referral', icon: '', Show: true },
        component: () => import('@/views/Referral'),
      },
      {
        path: '/UserReferral',
        meta: { title: 'UserReferral', icon: '', Show: true },
        component: () => import('@/views/UserReferral'),
      },
      {
        path: '/Task',
        meta: { title: 'Task', icon: '', Show: true },
        component: () => import('@/views/Task'),
      },
      {
        path: '/withdrawalManagement',
        meta: { title: 'withdrawalManagement', icon: '', Show: true },
        component: () => import('@/views/withdrawalManagement'),
      },
      {
        path: '/orderList',
        meta: { title: 'orderList', icon: '', Show: true },
        component: () => import('@/views/orderList'),

      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  let roleId = localStorage.getItem('roleId')
  router.options.routes[0].children.forEach((item, index) => {
    if (index > 1 && roleId == 2) {
      item.meta.Show = false
    } else {
      item.meta.Show = true
    }
  });


  document.title = to.name || process.env.VUE_APP_TITLE
  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    if (localStorage.getItem('token')) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
